import { of, from } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import { GST_API, API_ENDPOINT } from "../types/enums";
import { userModel } from "../models/userModel";
import fetchIntercept from 'fetch-intercept';
import SessionService from "./sessionService";

export default class ApiService {
  static apiHomeURL = `https://gst2dev.azurewebsites.net/Home`;
  static apiBaseURL = `${process.env.REACT_APP_BASE_HTTP}`;
  static apiCoreURL = `${process.env.REACT_APP_CORE_HTTP}`;
  static apiIdentityURL = `${process.env.REACT_APP_IDENTITY_HTTP}`;
  static apiTenantURL = `${process.env.REACT_APP_TENANT_HTTP}`;
  static apiReportURL = `${process.env.REACT_APP_REPORTS_HTTP}`;
  static apiIzendaCoreURL = `${process.env.REACT_APP_IZENDA_CORE_HTTP}`;
  static apiIzendaIdentityURL = `${process.env.REACT_APP_IZENDA_IDENTITY_HTTP}`;
  static oktaToken = null;
  static tokenToAuth ;

  static fetchObs(_url, _params = {}) {
    if (!(_url + "").startsWith(this.apiBaseURL)) {
      _url = this.apiBaseURL + _url;
     
    }
    _params.headers = _params.headers || {};

    // Add the Authorization token to the headers
    _params.headers['Authorization'] = `Bearer ` + ApiService.oktaToken?.accessToken?.accessToken;
    return from(fetch(_url, _params)).pipe(switchMap((response) => response.json()));
  }
  static fetchObs2(_url, _params = {}) {
    if (!(_url + "").startsWith(this.apiCoreURL)) {
      _url = this.apiCoreURL + _url;
    }
    _params.headers = _params.headers || {};

  // Add the Authorization token to the headers
  _params.headers['Authorization'] = `Bearer ` + ApiService.oktaToken?.accessToken?.accessToken;
    return from(fetch(_url, _params)).pipe(switchMap((response) => response.json()));
  }
  static fetchObsHome(_url) {
    if (!(_url + "").startsWith(this.apiHomeURL)) {
      _url = this.apiHomeURL + _url;
    }
    const requestOptions = {
      headers: {
        'Authorization': `Bearer ` + ApiService.oktaToken?.accessToken?.accessToken
      }
    };
    return from(fetch(_url, requestOptions)).pipe(
      switchMap((response) => response.json())
    );
  }
  // static fetchObsTenant(_url) {
  //   if (!(_url + "").startsWith(this.apiTenantURL)) {
  //     _url = this.apiTenantURL + _url;
  //   }
  //   return from(fetch(_url)).pipe(switchMap((response) => response.json()));
  // }

  static fetchObsTenant(_url) {
    if (!(_url + "").startsWith(this.apiTenantURL)) {
      _url = this.apiTenantURL + _url;
    }
    const requestOptions = {
      headers: {
        'Authorization': `Bearer ` + ApiService.oktaToken?.accessToken?.accessToken
      }
    };
    return from(fetch(_url, requestOptions)).pipe(
      switchMap((response) => response.json())
    );
  }

  static fetchObsidentity(_url) {
    if (!(_url + "").startsWith(this.apiIdentityURL)) {
      _url = this.apiIdentityURL + _url;
    }
    const requestOptions = {
      headers: {
        'Authorization': `Bearer ` + ApiService.oktaToken?.accessToken?.accessToken
      }
    };
    return from(fetch(_url, requestOptions)).pipe(
      switchMap((response) => response.json())
    );
  }
  static fetchHTTP_API(_enumAPI, _url, _params = {}) {
    let api_address = "";
    this.tokenToAuth = ApiService.oktaToken?.accessToken?.accessToken;
 
    
    if (_enumAPI === GST_API.Identity) api_address = `${process.env.REACT_APP_IDENTITY_HTTP}`;
    else if (_enumAPI === GST_API.TenantConfig) api_address = `${process.env.REACT_APP_TENANT_HTTP}`;
    else if (_enumAPI === GST_API.Core) api_address = `${process.env.REACT_APP_CORE_HTTP}`;
    else if (_enumAPI === GST_API.Reports) api_address = `${process.env.REACT_APP_REPORTS_HTTP}`;

    if (!(_url + "").startsWith(api_address)) {
      _url = api_address + _url;
    }

    if (Object.keys(_params).length === 0) {
      _params = {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
          "Content-Type": "application/json",
          "Authorization": `Bearer ` + ApiService.tokenToAuth,
        },
      };
    }
    return from(fetch(_url, _params)).pipe(switchMap((response) => response.json()));
  }


  // ---- NEW METHODS
  /** GET-VERB
   * @returns an observable, so the client needs to subscribe */
  static getOBS(
    _apiEndPointEnum,
    _urlString,
    _params = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + ApiService.oktaToken?.accessToken?.accessToken,
      },
    }
  ) {
    // 1) get the end point url
    let apiEndPointURL = ApiService._getEndPointURL(_apiEndPointEnum);

    // 2) Append the Api Point, if not already appended
    let urlString = _urlString + "";
    if (!urlString.startsWith(apiEndPointURL)) {
      urlString = apiEndPointURL + urlString;
    }

    // 3) if params is empty then set the params
    if (Object.keys(_params).length === 0) {
      _params = {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
          "Content-Type": "application/json",
          "Authorization": `Bearer ` + ApiService.oktaToken?.accessToken?.accessToken,
        },
      };
    }

    // fetch and return the result as observable
    let unParsedResponse = null;
    return from(fetch(urlString, _params)).pipe(
      switchMap((response) => {
        unParsedResponse = response;
        return response.json();
      }


      ),
      catchError((e) => {
        console.error(urlString, { 'error': e, 'response': unParsedResponse });
        // handle e and return a safe value or re-throw
        // if (isCritical(e)) {
        //   return throwError(e);
        // }
        return of(null);
      })
    );
  }

  /** PUT-VERB
   * @returns an observable, so the client needs to subscribe */
  static putOBS(_apiEndPointEnum, _urlString, _jsonStringifiedBody) {
    return ApiService.setOBS("PUT", _apiEndPointEnum, _urlString, _jsonStringifiedBody);
  }

  /** POST-VERB
   * @returns an observable, so the client needs to subscribe */
  static postOBS(_apiEndPointEnum, _urlString, _jsonStringifiedBody) {
    return ApiService.setOBS("POST", _apiEndPointEnum, _urlString, _jsonStringifiedBody);
  }

  /** DELETE-VERB
   * @returns an observable, so the client needs to subscribe */
  static deleteOBS(_apiEndPointEnum, _urlString, _jsonStringifiedBody) {
    return ApiService.setOBS("DELETE", _apiEndPointEnum, _urlString, _jsonStringifiedBody);
  }

  /**
   * @_actionVerb : "PUT" (or) "POST"
   * @returns an observable, so the client needs to subscribe */
  static setOBS(_actionVerb, _apiEndPointEnum, _urlString, _jsonStringifiedBody, _headers, _responseType = "json") {
    // 1) get the end point url
    let apiEndPointURL = ApiService._getEndPointURL(_apiEndPointEnum);
    this.tokenToAuth = ApiService.oktaToken?.accessToken?.accessToken;

    // 2) Append the Api Point, if not already appended
    if (!(_urlString + "").startsWith(apiEndPointURL)) {
      _urlString = apiEndPointURL + _urlString;
    }

    if (_headers === undefined) {
      _headers = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + ApiService.tokenToAuth,
      };
    }

    // 3) if params is empty then set the params
    const _params = {
      method: _actionVerb,
      headers: _headers,
      body: _jsonStringifiedBody
    };

    // fetch and return the result as observable
    if (_actionVerb === "PUT") {
      // PUT does not return any response body, it only returns a status code
      return from(fetch(_urlString, _params))
    } else {
      return from(fetch(_urlString, _params)).pipe(
        switchMap((response) => _responseType === "text" ? response.text() : response.json()
        )
      );
    }
  }

  /** Internal use only */
  static _getEndPointURL(_apiEndPointEnum) {
    switch (_apiEndPointEnum) {
      case API_ENDPOINT.CORE:
        return ApiService.apiCoreURL;
      case API_ENDPOINT.IDENTITY:
        return ApiService.apiIdentityURL;
      case API_ENDPOINT.HOME:
        return ApiService.apiHomeURL;
      case API_ENDPOINT.TENANT:
        return ApiService.apiTenantURL;
      case API_ENDPOINT.REPORTS:
        return ApiService.apiReportURL;
      case API_ENDPOINT.IZENDA_CORE:
        return ApiService.apiIzendaCoreURL;
      case API_ENDPOINT.IZENDA_IDENTITY:
        return ApiService.apiIzendaIdentityURL;
      case API_ENDPOINT.BASE:
      default:
        return ApiService.apiBaseURL;
    }
  }
}
const unregister = fetchIntercept.register({
  request: function (url, config) {
    const fetchOptions = {
      ...config,
    }
    try {
      if (!fetchOptions.headers) {
        fetchOptions.headers = {};
      }
      var ISSUER = process.env.REACT_APP_ISSUER.split('.com')[0] + '.com';
      if (localStorage.getItem("okta-token-storage") !== "{}" && localStorage.getItem("okta-token-storage") !== null &&
        localStorage.getItem("okta-token-storage") !== undefined && !(url.startsWith(ISSUER))) {
        fetchOptions.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("okta-token-storage"))?.accessToken?.accessToken}`;
      }
    } catch (error) {
      console.log("error in intercept", error);
    }
    return [url, fetchOptions];
  }
})